import React, { useEffect } from 'react';
import Resizer from "react-image-file-resizer";
import { apiUrl, MediaUpload } from '../../../../Constant/api';

export default function FileUpload(props) {
  const {
    ImageUrl,
    setImageUrl,
    MediaUploadType,
    setMediaUploadType,
  } = props;
  const fileRefSingle = React.createRef();
  const [Loading, setLoading] = React.useState(false)
  const [URL, setURL] = React.useState("")
  const [PreviewPressed, setPreviewPressed] = React.useState(false)

  const MediaUploadSingleCall = (file, i) => {
    if (!file) return;
    var fileInput = "";
    if (file) {
      fileInput = file.type;
    }
    console.log("2", file)
    // setMediaUploadType(file.type.split("/")[0])
    MediaUpload(file)
      .then(res => res.json())
      .then(data => {
        console.log(data)
        console.log(data.source_url)
        setImageUrl(data.source_url)
        setURL(data.source_url)
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      })
      .catch(err => { console.log("error", err.message); })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      {Loading &&
        <div className="spinner-border"></div>
      }
      <div className="">
        <div className="row">
          {ImageUrl !== "" ?
            <div className="my-2">
              {/* <a href={ImageUrl} target="_blank" className="Link"> */}
              <span style={{ cursor: "pointer" }} onClick={() => { setPreviewPressed(true) }}>

                {ImageUrl.split("/")[ImageUrl.split("/").length - 1]}
                {/* {ImageUrl.replace(`${apiUrl}wp-content/uploads/`, "").slice(8)} */}
              </span>
              {/* </a> */}
              <button onClick={() => {
                setImageUrl("")
              }}
                className="bg-transparent border-0 p-1 "
              ><u>Clear</u></button>
            </div>
            :
            <div className="col-3">
              <button type="button"
                onClick={() => {
                  fileRefSingle.current.click()
                }}
                className="bg-transparent border-0 p-1 "
              >
                <div className="border border-dark m-1 p-4 font-size-12">
                  <i className="fa fa-file-pdf-o font-size-18"></i>
                  <div>Add</div>
                </div>
              </button>
            </div>
          }
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <input type="file" accept="file/*" name="image" id="file" ref={fileRefSingle}
          onChange={(event) => {
            if (event.target.files.length !== 0) {
              setLoading(true)
              MediaUploadSingleCall(event.target.files[0])
            }
          }}
        />
      </div>
      <div style={{ fontSize: "8px", color: "#777" }}>
        The pdf or book uploaded should not have the copyright with some other company or party.<br></br>
        Please try not to upload pdfs that may later have compyright issues
      </div>
      {PreviewPressed &&
        <div className="modal">
          <div className="row">
            <div className="col-1" />
            <div class="modal-dialog" role="document" className="col-10">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    onClick={() => { setPreviewPressed(false) }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div style={{ minHeight: "78vh", overflowY: "scroll" }}>
                    <iframe
                      style={{ width: "100%", height: "80vh" }}
                      src={ImageUrl}></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}